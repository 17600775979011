body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  cursor: pointer;
}

.team-match-wrapper.activated {
  background-color: rgba(0, 0, 0, 0.35);
  visibility: visible;
}

.team-match-wrapper {
  content: '';
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 0;
  background-color: transparent;
  transition: 0.3s;
}

.team-match-container.activated {
  height: 100vh;
}

.team-match-container {
  height: 0;
  width: 100%;
  max-width: 900px;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #efefef;
  transition: 0.3s;
}

.team-match-container-header {
  text-align: center;
  margin-top: 64px;
  margin-bottom: 24px;
}

.team-match-container img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid black;
}